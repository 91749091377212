import axios from 'axios';
import {JWT_ACCESS_TOKEN, JWT_REFRESH_TOKEN} from '../utils/constants';

axios.defaults.headers.common['Accept'] = 'application/json';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

// Добавляем интерцептор запроса
api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem(JWT_ACCESS_TOKEN);
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

// Добавляем интерцептор ответа
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        // console.log('Interceptor Error:', error);
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._isRetry) {
            originalRequest._isRetry = true;
            const refreshToken = localStorage.getItem(JWT_REFRESH_TOKEN);

            if (refreshToken) {
                try {
                    // Запрос на обновление токена
                    const tokenResponse = await api.post('/api/auth/refresh', {
                        refreshToken,
                    });

                    // console.log('Token refreshed', tokenResponse.data);
                    const {accessToken, refreshToken: newRefreshToken} = tokenResponse.data;
                    localStorage.setItem(JWT_ACCESS_TOKEN, accessToken);
                    localStorage.setItem(JWT_REFRESH_TOKEN, newRefreshToken); // Сохраняем новый refreshToken

                    originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

                    // Повторяем исходный запрос с обновленным токеном
                    return api(originalRequest);
                } catch (refreshError) {
                    console.error('Token refresh failed:', refreshError);
                    localStorage.removeItem(JWT_ACCESS_TOKEN);
                    localStorage.removeItem(JWT_REFRESH_TOKEN);
                    window.location.href = '/login';

                    return Promise.reject(refreshError);
                }
            } else {
                // Возвращаемся к начальному состоянию, если refreshToken отсутствует
                console.error('Refresh token not found');
                localStorage.removeItem(JWT_ACCESS_TOKEN);
                localStorage.removeItem(JWT_REFRESH_TOKEN);
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    },
);

// Получение всех постов
const getPosts = async (skip, limit) => {
    const response = await api.get('/api/posts', {params: {skip, limit}});
    return response.data;
};

//Получение постов пользователя по ID
const getMyPosts = async (user_id) => {
    const response = await api.get(`/api/users/${user_id}/posts`);
    return response.data;
};

// Удаление поста по его Id
const deletePost = async (postId, config = {}) => {
    const response = await api.delete(`/api/posts/${postId}`, config);
    return response.data;
};

//Создание поста
const createPost = async (postData) => {
    const response = await api.post('/api/posts', postData);
    return response.data; // Предполагается, что весь список постов обновляется в другом месте приложения
};

// Функция обновления поста по его ID
const updatePost = async (postData, postId) => {
    // console.log('1===> updatePost Api postData:', postData); // Логирование данных для обновления
    // console.log('2===> updatePost Api postId:', postId); // Логирование идентификатора поста
    try {
        const response = await api.put(`/api/posts/${postId}`, postData);
        const updatePost = response.data;
        // console.log('updatePost api.put /api/posts:', updatePost);
        return updatePost;
    } catch (error) {
        console.error('Error updating post:', error.message);
        throw error;
    }
};

//Получение постов пользователя по ID
const getUserPosts = async (userId, skip, limit) => {
    const response = await api.get(`/api/users/${userId}/posts`, {
        params: {skip, limit},
    });
    return response.data;
};

//Получение данных авторизованного пользователя
const getMe = async () => {
    const response = await api.get(`/api/me`);
    return response.data;
};

// Получение данных о конкретном посте по его ID
const getPostById = async (postId) => {
    try {
        const response = await api.get(`/api/posts/${postId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching post by ID:', error.message);
        throw error;
    }
};

// Обновление данных о пользователя по его ID
const updateUser = async (userId, userData) => {
    try {
        const response = await api.put(`/api/users/${userId}`, userData);
        return response.data;
    } catch (error) {
        console.error('Error userData Update', error.message);
        throw error;
    }
};

const updatePassword = async (passwordData) => {
    try {
        const response = await api.put('/api/user/password/update', passwordData);
        return response.data;
    } catch (error) {
        console.error('Error password update:', error.message);
        throw error;
    }
};

//Для формы обратной связи Contact
const createFeedback = async (feedbackData) => {
    try {
        const response = await api.post('/api/feedback', feedbackData);
        return response.data;
    } catch (error) {
        console.error('Error creating feedback:', error.message);
        throw error;
    }
};

//Для получения юзеров на страницу Админа
const getUsers = async (skip, limit) => {
    try {
        const response = await api.get('/api/users', {params: {skip, limit}});
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error.message);
        throw error;
    }
};

//Для поиска юзера по email на странице Админа
const getUsersEmail = async (email, skip, limit) => {
    try {
        const response = await api.get('/api/users', {
            params: {email, skip, limit},
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error.message);
        throw error;
    }
};

// Удаление пользователя по ID
const deleteUser = async (userId) => {
    try {
        const response = await api.delete(`/api/users/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting user:', error.message);
        throw error;
    }
};

export default api;
export {
    createPost,
    deletePost,
    getUserPosts,
    getMe,
    getMyPosts,
    getPosts,
    getPostById,
    updatePost,
    updateUser,
    updatePassword,
    createFeedback,
    getUsers,
    getUsersEmail,
    deleteUser,
};
