import { Navigate } from 'react-router-dom';
import { useAuth } from './index';

function RequireAuth({ children }) {
  const { user, isLoading } = useAuth();
  // console.log('Current user state:', user);

  if (isLoading) {
    // console.log('Loading user data...');
    return <div>Loading...</div>;
  }

  if (!user) {
    // console.log('No user found, redirecting to login');
    return <Navigate to='/login' />;
  }

  return children;
}

export default RequireAuth;
